/*Module*/
.parcours {
  padding-top: 15vh;
}
.info {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 10px;
}
.info h3 {
}
/*Title*/
.parcours h1 {
  color: var(--primary-subcolor);
  padding-bottom: 10px;
  border-bottom: solid 1px var(--secondary-color);
  border: solid var(--secondary-color);
  border-width: 0px 0 1px 0px;
  margin: auto;
  max-width: 185px;
  text-shadow: 0 -1px 1.5px var(--secondary-subcolor);
  /*
  -webkit-animation: popInText 0.85s linear 2s both;
  animation: popInText 0.85s linear 2s both;
  */
}
/*Carreer paragraph*/
.parcours p {
  font-size: 18px;
  line-height: 32px;
  text-align: justify;
}
/*Carreer card*/
.card {
  position: relative;
}
/*Carreer title*/
.title {
  color: var(--primary-subcolor);
  position: relative;
  margin: unset;
  margin-bottom: 10px;
}
/*Carreer subtitle*/
.subTitle {
  color: var(--secondary-subcolor);
  font-size: 20px;
  line-height: 12px;
  text-align: justify;
  font-style: italic;
  cursor: none;
  pointer-events: none;
}
/*Possible link */
.thisLinks {
  position: absolute;
  color: var(--blackish);
  pointer-events: auto;
  width: 278px;
  background-color: var(--secondary-color);
  border-radius: 5px;
  padding: 10px;
  margin-top: 55px;
  cursor: pointer;
  transition: all 0.5s;
  font-family: "Titillium Web", sans-serif;
  text-transform: uppercase;
  text-align: center;
}
.thisLinks:hover,
.ithisLinks:focus,
.ithisLinks:visited,
.thisLinks:hover {
  color: var(--blackish);
  -webkit-animation: pulseButton 1.5s ease-in-out infinite both;
  animation: pulseButton 1.5s ease-in-out infinite both;
}
/*Carreer border styling*/
.card::before {
  content: "";
  position: absolute;
  width: 50%;
  border: solid var(--secondary-color);
  opacity: 0.7;
  pointer-events: none;
}
/*styling if carreer card is odd or even*/
.card:nth-child(odd) {
  padding: 30px 0 30px 30px;
}
.card:nth-child(odd)::before {
  left: 0px;
  top: -2px;
  bottom: -2px;
  border-width: 2px 0 2px 2px;
  border-radius: 50px 0px 0px 50px;
}
.card:nth-child(even) {
  padding: 30px 30px 30px 0;
}
.card:nth-child(even)::before {
  right: 0;
  top: 0;
  bottom: 0;
  border-width: 2px 2px 2px 0;
  border-radius: 0px 50px 50px 0px;
}
/*styling if carreer card is first or last*/
.card:first-child::before {
  left: 0px;
  top: 46px;
  bottom: -2px;
  border-width: 0 0 2px 2px;
  border-radius: 0px 0px 0px 50px;
}
.card:last-child:nth-child(even)::before {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  top: 0;
  bottom: 40px;
}
.subTitleR {
  text-align: right;
}
.title2 {
  color: var(--primary-subcolor);
  font-size: 20px;
  line-height: 12px;
  text-align: justify;
  margin-top: 15px;
}
.title3 {
  text-align: right;
}
.card:nth-child(even) > .info > .title {
  text-align: right;
}
.cardImgL {
  height: auto;

  width: 350px;
  float: left;
  margin: 0 20px 0 0;
  border-radius: 0px 20px 20px 0px;
  height: auto;
}
.cardImgR {
  height: auto;

  max-width: 400px;
  float: right;
  margin: 0 0 0 20px;
  border-radius: 20px 0px 0px 20px;
  height: auto;
}

/*Career card entrance animation*/
/*
.Carreer1 {
  -webkit-animation: CarreerLeftEntrance 0.4s
    cubic-bezier(0.25, 0.46, 0.5, 0.94) 2.45s both;
  animation: CarreerLeftEntrance 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) 2.45s
    both;
}

.Carreer2 {
  -webkit-animation: CarreerRightEntrance 0.4s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) 2.85s both;
  animation: CarreerRightEntrance 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    2.85s both;
}

.Carreer3 {
  -webkit-animation: CarreerLeftEntrance 0.4s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) 3.25s both;
  animation: CarreerLeftEntrance 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) 3.25s
    both;
}
.Carreer4 {
  -webkit-animation: CarreerRightEntrance 0.4s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) 3.65s both;
  animation: CarreerRightEntrance 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    3.65s both;
}
*/
