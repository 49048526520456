/*Module*/
.contact {
  padding: 15px 0 15px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  margin: auto;
  /*
  -webkit-animation: Cappear 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) 2s both;
  animation: Cappear 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) 2s both;
  */
}
.contact-text {
  width: 100%;
  justify-content: center;
  text-align: justify;
  padding-top: 10px;
}
/*Title*/
.contact h1 {
  font-size: 28px;
  position: absolute;
  top: 25px;
  left: 50%;
  transform: translate(-50%, -50%);
  border-bottom: solid 1px var(--secondary-color);
  padding: 10px;
  color: var(--primary-subcolor);
  text-shadow: 0 -1px 1.5px var(--secondary-subcolor);
}
/*Icons/Links Wrapper*/
.cInfos {
  width: 98%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 25px 10px 0 10px;
  flex-wrap: wrap;
}
/*Icons*/
.cInfos img {
  width: 45px;
  height: auto;
  margin: auto;
  border-radius: 50px;
  -webkit-box-shadow: 0 0 7px 1px var(--secondary-color);
  box-shadow: 0 0 7px 1px var(--secondary-color);
  -webkit-animation: iconRotate2 0.6s ease-in-out reverse both;
  animation: iconRotate2 0.6s ease-in-out reverse both;
}
/*Icons/Links cards*/
.cInfosDiv {
  margin: 20px auto;
  width: 25%;
  transform: scale(1.1);
  transition: 0.7s;
}
/*Icons/Links cards paragraphs*/
.cInfosDiv p {
  font-size: 16px;
  line-height: unset;
  border-bottom: unset;
  padding-bottom: unset;
  margin: 10px;
}
/*Icons/Links hover animation*/
.cInfosDiv:hover {
  transform: scale(1.2);
}
.cInfosDiv:hover p {
  color: var(--secondary-color);
}
.cInfosDiv:hover img {
  -webkit-animation: iconRotate 0.6s ease-in-out both;
  animation: iconRotate 0.6s ease-in-out both;
}
