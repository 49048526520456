/*Module*/
.intro {
  padding-top: 30vh;
  padding-bottom: 15vh;
}
.intro .row {
  display: flex;
  flex-direction: row;
  align-content: space-between;
}
/*Left part*/
.intro-text {
  flex: 1;
  max-width: 565px;
  justify-content: center;
  margin: 10px;
  -webkit-animation: popInText 0.85s linear 2.2s both;
  animation: popInText 0.85s linear 2.2s both;
  text-align: justify;
  padding-top: 30px;
  border-bottom: solid 1px var(--secondary-color);
}
/*Title*/
.intro-text h1 {
  color: var(--primary-subcolor);
  border-bottom: solid 1px var(--secondary-color);
  padding-bottom: 10px;
  text-shadow: 0 -1px 1.5px var(--secondary-subcolor);
}
/*Paragraph*/
.intro-text p {
  font-size: 22px;
  line-height: 32px;
  padding-bottom: 20px;
}
/*Right part*/
.intro-img {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10px;
  -webkit-animation: popInPhoto 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 2s
    both;
  animation: popInPhoto 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 2s both;
}
/*Photo*/
.authorPhoto {
  height: auto;
  width: auto;
  animation: movingImg 9s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite
    alternate both;
}
/*Mail*/
.intro .cInfosDiv {
  transform: scale(1.1);
  transition: 0.7s;
}
.intro .cInfosDiv p {
  color: var(--secondary-color);
  line-height: unset;
  border-bottom: unset;
  padding-bottom: unset;
  margin: 10px;
  border-bottom: unset;
}
.intro .cInfosDiv img {
  width: 55px;
  height: auto;
  margin: auto;
  border-radius: 50px;
  -webkit-box-shadow: 0 0 4px 1px var(--secondary-color);
  box-shadow: 0 0 4px 1px var(--secondary-color);
  -webkit-animation: iconRotate2 0.6s ease-in-out reverse both;
  animation: iconRotate2 0.6s ease-in-out reverse both;
}
.intro .cInfosDiv a {
  display: flex;
  justify-content: space-around;
}
.intro .cInfosDiv:hover {
  transform: scale(1.2);
}
.cInfosDiv:hover p {
  color: var(--primary-subcolor);
}
.intro .cInfosDiv:hover img {
  -webkit-animation: iconRotate 0.6s ease-in-out both;
  animation: iconRotate 0.6s ease-in-out both;
}
