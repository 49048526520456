/*Module*/
#menu {
  padding: 15px 50px 15px 50px;
  border: unset;
  padding-top: 25px;
  background: url("./background.webp"), var(--blackish);
  background-attachment: fixed;
  background-blend-mode: overlay;
  background-size: cover;
}
#menu a.navbar-brand {
  font-family: "Titillium Web", sans-serif;
  font-size: 24px;
  font-weight: 100;
  text-transform: uppercase;
}
#menu.navbar-default .navbar-nav > li > a {
  font-family: "Titillium Web", sans-serif;
  text-transform: uppercase;
  color: var(--primary-subcolor);
  font-size: 19px;
  font-weight: 400;
  padding: 8px 2px;
  border-radius: 0;
  margin: 15px 20px 0;
}
#menu.navbar-default .navbar-nav > li > a:after {
  display: block;
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 0;
  height: 2px;
  background: var(--secondary-color);
  content: "";
  transition: width 0.2s;
}
#menu.navbar-default .navbar-nav > li > a:hover:after {
  width: 100%;
}
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
  background-color: transparent;
}
.navbar-default .navbar-nav > .active > a:after,
.navbar-default .navbar-nav > .active > a:hover:after,
.navbar-default .navbar-nav > .active > a:focus:after {
  display: block !important;
  position: absolute !important;
  left: 0 !important;
  bottom: -1px !important;
  width: 100% !important;
  height: 2px !important;
  background: white;
  content: "" !important;
  transition: width 0.2s !important;
}
.navbar-toggle {
  border-radius: 0;
}
.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
  background-color: var(--whiteish);
  border-color: var(--whiteish);
}
.navbar-default .navbar-toggle:hover > .icon-bar {
  background-color: var(--blackish);
}
a:focus,
.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
  outline: none;
  outline-offset: none;
}
/*Site logo & entrance animation*/
.authorLogo {
  width: auto;
  height: auto;
  max-height: 70px;
  -webkit-animation: Alogo 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.5s both;
  animation: Alogo 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.5s both;
}
/*Scrollable section title*/
.section-title {
  margin-bottom: 70px;
}
.section-title h2 {
  text-shadow: 1px 1px 2px black;
  position: relative;
  margin-top: 30px;
  margin-bottom: 15px;
  padding-bottom: 15px;
}
/*Scrollable section title underline*/
.section-title h2::after {
  position: absolute;
  content: "";
  background: white;
  height: 4px;
  width: 60px;
  bottom: 0;
  margin-left: -30px;
  left: 50%;
}
.section-title p {
  font-size: 18px;
}
/*Scrollable section title entrance animation*/
.one {
  -webkit-animation: menuA 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) 1s both;
  animation: menuA 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) 1s both;
}
.two {
  -webkit-animation: menuA 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) 1.2s
    both;
  animation: menuA 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) 1.2s both;
}
.three {
  -webkit-animation: menuA 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) 1.4s
    both;
  animation: menuA 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) 1.4s both;
}
.four {
  -webkit-animation: menuA 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) 1.6s
    both;
  animation: menuA 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) 1.6s both;
}
.five {
  -webkit-animation: menuA 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) 1.8s
    both;
  animation: menuA 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) 1.8s both;
}
