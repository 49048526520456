/**********1199*********************1199*********************1199***********/
@media (max-width: 1199px) {
  /*Header/Nav*/
  .authorPhoto {
    margin-top: 100px;
  }
  .authorLogo {
    margin-top: 10px;
    max-height: 60px;
  }
  #menu.navbar-default .navbar-nav > li > a {
    font-size: 17px;
  }
}
/**********1085*********************1085*********************1085***********/
@media (max-width: 1085px) {
  /*Header/Nav*/
  .authorLogo {
    max-height: 50px;
  }
  #menu.navbar-default .navbar-nav > li > a {
    font-size: 15px;
  }
}
/**********992px*********************992px*********************992px***********/
@media (max-width: 992px) {
  /*CV*/
  .cv {
    padding-top: 20vh;
    padding-bottom: unset;
  }
  .cv .row {
    display: flex;
    flex-direction: column;
  }
  .cv .row > * {
    margin: auto;
  }
  .skillsPhoto {
    margin-top: 30px;
    max-height: 450px;
  }
  /*Intro*/
  .intro {
    padding-top: 20vh;
    padding-bottom: unset;
  }
  .intro .row {
    display: flex;
    flex-direction: column;
  }
  .intro .row > * {
    margin: auto;
  }
  /*Header/Nav*/
  #menu {
    border-bottom: solid 1px var(--secondary-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  #menu > * {
    margin: auto;
  }
  .authorLogo {
    max-height: 60px;
  }
  #menu.navbar-default .navbar-nav > li > a {
    font-size: 18px;
  }
  /*Parcours*/
  .parcours .row {
    max-width: 99%;
    margin: auto;
    display: flex;
    flex-direction: column;
  }
  .parcours {
    padding-top: 25vh;
    padding-bottom: unset;
    padding-left: 25px;
    padding-right: 25px;
  }
  .parcours .row > * {
    margin: auto;
  }
  .thisLinks {
    position: relative;
    width: 100%;
    margin: auto;
    text-align: center;
  }
}
/**********767**********************767**********************767************/
@media (max-width: 767px) {
  /*CV*/
  .cv {
    padding-left: 50px;
    padding-right: 50px;
  }
  .cv h2 {
    font-size: 20px;
    line-height: 20px;
  }
  /*Header/Nav*/
  navbar-default .navbar-collapse,
  .navbar-default .navbar-form {
    border-color: var(--secondary-color);
  }
  #menu button > * {
    background-color: var(--secondary-color);
  }
  * {
    animation: none !important;
  }
  #menu {
    display: unset;
  }
  .authorLogo {
    max-height: 63px;
    margin-bottom: 20px;
  }
  .navbar-default .navbar-toggle {
    border-color: var(--secondary-color);
  }
  /*Parcours*/
  .parcours .title {
    font-size: 26px;
  }
  .parcours .subTitle,
  .parcours .title2 {
    font-size: 18px;
  }
  .parcours .title2 {
    font-size: 18px;
    line-height: 20px;
  }
}
/**********688**********************688**********************688************/
@media (max-width: 688px) {
  /*404*/
  .quatre1 b {
    font-size: 150px;
  }
  .quatre2 b {
    font-size: 35px;
  }
  .quatre4 p {
    font-size: 25px;
  }
  /*Contact*/
  .cInfosDiv p {
    color: var(--secondary-color);
    visibility: hidden;
  }
  .cInfosDivtel {
    position: relative;
  }
  .cInfosDivtel p {
    position: absolute;
  }
  .cInfosDivtel:hover p {
    visibility: visible;
  }
  /*Intro*/
  .intro {
    padding-left: 50px;
    padding-right: 50px;
  }
  .intro .cInfosDiv p {
    display: none;
  }
  /*project*/
  .card p {
    font-size: 18px;
    line-height: 28px;
  }
  /*Parcours*/
  .parcours img {
    width: 310px;
  }
  .parcours {
    padding-left: 50px;
    padding-right: 50px;
  }
  .parcours .title {
    font-size: 24px;
  }
  .parcours .subTitle,
  .parcours .title2 {
    font-size: 16px;
  }
  .parcours .title2 {
    font-size: 16px;
    line-height: 20px;
  }
}
/**********600**********************600**********************600************/
@media (max-width: 600px) {
  /*404*/
  .quatre1 b {
    font-size: 125px;
  }
  .quatre2 b {
    font-size: 30px;
  }
  .quatre4 p {
    font-size: 20px;
  }
  /*Parcours*/
  .parcours .inContent {
    display: flex;
    flex-direction: column;
  }
  .parcours img {
    margin: auto;
    max-width: 400px;
    border-radius: 5px;
    margin-bottom: 20px;
  }
}
/**********540***********************540*************************540*******/
@media (max-width: 540px) {
  /*Contact*/
  .cInfosDivtel p {
    left: -100%;
    transform: translate(-28%, 30%);
  }
}
/**********530**********************530**********************530************/
@media (max-width: 530px) {
  /*General*/
  .contact p,
  .cv p,
  .intro p,
  .parcours p {
    font-size: 17px;
    line-height: 24px;
  }
  .cv p,
  .contact p,
  .intro p {
    font-size: 15px;
    line-height: 18px;
  }
  /*404*/
  .quatre1 b {
    font-size: 115px;
  }
  /*CV*/
  .cv {
    padding-top: 12vh;
    padding-left: 15px;
    padding-right: 15px;
  }
  .cv h2 {
    font-size: 17px;
  }
  .skillsPhoto {
    max-height: 300px;
    margin: 15px 0 15px 0;
  }
  .cvConsult {
    display: none;
  }
  .cvDownload {
    width: 90vw;
  }
  .buttonWrap {
    display: flex;
    justify-content: center;
  }
  /*intro*/
  .intro {
    padding-top: 12vh;
    padding-left: 15px;
    padding-right: 15px;
  }
  /*Header/Nav*/
  .authorLogo {
    max-height: 50px;
    margin-top: unset;
  }
  #menu {
    padding: 15px;
  }
  .card:nth-child(odd) {
    padding: 10px 0 10px 10px;
  }
  .card:nth-child(even) {
    padding: 10px 10px 10px 0;
  }

  /*Parcours*/
  .parcours p {
    font-size: 14px;
    line-height: 18px;
  }
  .parcours .row {
    margin: unset;
    max-width: unset;
  }
  .parcours {
    width: 100%;
    padding: unset;
    padding-top: 12vh;
  }
  .parcours .subTitle {
    line-height: unset;
  }
  /*Projects*/
  .réalisations {
    width: 100%;
    padding: unset;
    padding-top: 13vh;
  }
  .projectCard {
    max-width: unset;
    padding: 30px;
    margin: unset;
  }
  .projectCard button {
    width: 80vw;
  }
  .projectCard p {
    text-align: justify;
    margin: 10px;
    height: unset;
    min-height: unset;
    max-height: unset;
  }
  .réalisations .container {
    width: 100%;
    padding: 10px;
  }
  .réalisations h1 {
    font-size: 24px;
    max-width: 275px;
    margin-bottom: unset;
  }
  .réalisations p {
    font-size: 15px;
    line-height: 20px;
  }
  .otherCardsFade > *:hover {
    opacity: 1;
    transform: scale(1);
  }
  .otherCardsFade:hover > * {
    opacity: 1;
    transform: scale(1);
  }
  .parcours .title {
    font-size: 16px;
    text-align: center;
  }
  .thisLinks {
    width: auto;
  }
  .card:nth-child(even) > .info > .title {
    text-align: center;
  }
  .parcours .subTitle,
  .parcours .title2,
  .parcours .subTitleR {
    font-size: 13px;
    text-align: center;
  }
  .parcours img {
    width: 90%;
  }
  /*Contact*/
  .cInfosDivtel p {
    left: -100%;
    transform: translate(-40%, 30%);
  }
  /*Intro*/
  .intro .cInfosDiv img {
    width: 45px;
    height: auto;
  }
}
/**********405**********************405**********************405************/
@media (max-width: 405px) {
  /*Genéral*/
  .cv h1,
  .contact h1,
  .intro h1,
  .parcours h1,
  .réalisations h1 {
    font-size: 24px;
  }
  /*404*/
  .quatre1 b {
    font-size: 85px;
  }
  .quatre2 b {
    font-size: 28px;
  }
  .quatre4 p {
    font-size: 18px;
  }
  /*Header/Nav*/
  .authorLogo {
    margin-top: 5px;
    max-height: 39px;
  }
  /*Projects*/
  .réalisations {
    padding-top: 12vh;
  }
}
