/***FONTS***/
@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@200&family=Titillium+Web:wght@700&display=swap");
/*BIGGER*/
@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@900&display=swap");

/***COMPONENTS STYLES***/
@import "./animations.css";
@import "./contact.css";
@import "./cv.css";
@import "./header.css";
@import "./intro.css";
@import "./parcours.css";
@import "./projets.css";
@import "./responsive.css";
@import "./404.css";

/*Color Variables*/
body {
  --blackish: #222020;
  --whiteish: #f9f7fd;
  --primary-color: #31525b;
  --primary-subcolor: #b3dee5;
  --secondary-color: #ffa101;
  --secondary-subcolor: #fae6b1;
}

/*General*/
body,
html {
  font-family: "Roboto Slab", serif;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  font-weight: 400;
  width: 100% !important;
  height: 100% !important;
  color: var(--whiteish);
  background: url("./background.webp"), var(--blackish);
  background-attachment: fixed;
  background-blend-mode: overlay;
  background-size: cover;
}
h1,
h2,
h3,
h4 {
  margin: 0 0 20px;
  font-weight: 800;
  font-size: 36px;
  transition: 0.3s;
  color: var(--whiteish);
}
h1 {
  font-family: "Titillium Web", sans-serif;
  text-transform: uppercase;
}
h2 {
  font-weight: 600;
  font-size: 36px;
}
h3 {
  font-weight: 600;
  font-size: 30px;
}
h4 {
  font-weight: 300;
  font-size: 24px;
}
a,
p {
  font-size: 18px;
  color: var(--whiteish);
  transition: 0.3s;
}
a:hover,
a:focus {
  text-decoration: none;
}
button {
  margin: auto;
  background-color: var(--secondary-color);
  border: none;
  outline: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  transition: all 0.5s;
}
