/*Module*/
.réalisations {
  padding-top: 15vh;
  padding-bottom: 10vh;
}
.réalisations .container {
  padding: unset;
  width: 70%;
}
.réalisations .row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
/*Title*/
.réalisations h1 {
  text-shadow: 0 -1px 1.5px var(--secondary-subcolor);
  color: var(--primary-subcolor);
  padding-bottom: 10px;
  border-bottom: solid 1px var(--secondary-color);
  margin: auto auto 70px auto;
  max-width: 245px;
}
/*Project cards*/
.projectCard {
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 10px;
  padding-bottom: 10px;
  border-radius: 20px;
  max-width: 424px;
  width: 100%;
  line-height: 19px;
  margin-bottom: 70px;
  transition: 0.5s;
}
/*Project image*/
.projectCardImg {
  height: auto;
  margin-bottom: 20px;
  width: 100%;
  border: 2px solid var(--secondary-color);
  box-shadow: 0 6px 0px 1px #6c5003;
  border-radius: 18px;
  padding: 5px;
  filter: saturate(0.9);
}
/*Project image hover animation*/
.projectCard:hover .projectCardImg {
  filter: saturate(1.1);
  transition: 0.5s;
}
/*Project title*/
.projectCard h2 {
  color: var(--primary-subcolor);
  margin: 10px 10px 5px 10px;
  font-weight: 600;
  font-size: 24px;
  text-align: left;
  padding-left: 0px;
}
/*Project description*/
.projectCard h3 {
  margin: 0 10px 0 10px;
  text-align: left;
  color: var(--secondary-subcolor);
  font-size: 17px;
  font-style: italic;
  height: 38px;
  min-height: 38px;
  max-height: 38px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
/*Project details*/
.projectCard p {
  text-align: justify;
  margin: 10px 10px 0 10px;
  height: 152px;
  min-height: 152px;
  max-height: 152px;
}
/*Project techs wrapper*/
.techs {
  text-align: left;
  padding: 0 10px 0 10px;
  height: 45px;
  min-height: 45px;
  max-height: 45px;
  margin-top: 10px;
}
/*Tech icons*/
.techs img {
  height: 25px;
  width: auto;
  max-height: 25px;
  margin: 0 3px 0 3px;
}
/*Buttons wrapper*/
.buttonW {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 10px;
}
/*Buttons*/
.projectCard button {
  margin: 5px;
  font-family: "Titillium Web", sans-serif;
  text-transform: uppercase;
  color: var(--blackish);
  font-size: 16px;
}
.buttonNotNeeded {
  display: none;
}
.soloButton {
  display: flex;
  justify-content: center;
  width: 100%;
}
.soloButton button {
  width: 100%;
}
/*Buttons hover animation*/
.projectCard button:hover {
  -webkit-animation: pulseButton 1.5s ease-in-out infinite both;
  animation: pulseButton 1.5s ease-in-out infinite both;
}
/*Project cards hover animation*/
.otherCardsFade {
  visibility: hidden;
}
.otherCardsFade > * {
  visibility: visible;
  transition: opacity 150ms linear 100ms, transform 150ms ease-in-out 100ms;
}
.otherCardsFade:hover > * {
  opacity: 0.5;
  transform: scale(0.99);
  transition: 0.4s;
}
.otherCardsFade > *:hover {
  opacity: 1;
  transform: scale(1.03);
  transition: 0.4s;
}
.projectCard:hover .techs img {
  -webkit-animation: techAnim 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: techAnim 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.projectCard:hover .projectCardImg {
  filter: saturate(1.1);
  transition: 0.5s;
}
/*Tech icons entrance on hover animation*/
.projectCard:hover .techs > :nth-child(1) {
  -webkit-animation: techAnim 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: techAnim 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.projectCard:hover .techs > :nth-child(2) {
  -webkit-animation: techAnim 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s both;
  animation: techAnim 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s both;
}
.projectCard:hover .techs > :nth-child(3) {
  -webkit-animation: techAnim 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.4s both;
  animation: techAnim 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.4s both;
}
.projectCard:hover .techs > :nth-child(4) {
  -webkit-animation: techAnim 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.6s both;
  animation: techAnim 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.6s both;
}
.projectCard:hover .techs > :nth-child(5) {
  -webkit-animation: techAnim 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.8s both;
  animation: techAnim 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.8s both;
}
.projectCard:hover .techs > :nth-child(6) {
  -webkit-animation: techAnim 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1s both;
  animation: techAnim 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1s both;
}
.projectCard:hover .techs > :nth-child(7) {
  -webkit-animation: techAnim 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1.2s both;
  animation: techAnim 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1.2s both;
}
.projectCard:hover .techs > :nth-child(8) {
  -webkit-animation: techAnim 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1.4s both;
  animation: techAnim 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1.4s both;
}
.projectCard:hover .techs > :nth-child(9) {
  -webkit-animation: techAnim 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1.6s both;
  animation: techAnim 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1.6s both;
}
.projectCard:hover .techs > :nth-child(10) {
  -webkit-animation: techAnim 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1.8s both;
  animation: techAnim 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1.8s both;
}
